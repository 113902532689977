import * as React from "react"

import Layout from "../components/Layout"
import NavWithLogo from "../components/Layout/NavWithLogo"
import Seo from "../components/seo"
import PageContainer from "../components/PageContainer"
import HeroWithLogo from "../components/HeroWithLogo"

const PrivacyPolicy = () => (
  <Layout nav={<NavWithLogo />}>
    <Seo title="Privacy Policy" />
    <PageContainer>
      <HeroWithLogo />
      <h1>Privacy Statement</h1>

      <h2>1. General information and principles of data processing</h2>
      <p>We are pleased that you visit our website.</p>
      <p>
        The protection of your privacy and the protection of your personal data,
        the so-called personal data, when using our website is an important
        concern for us.
      </p>
      <p>
        According to Art. 4 No. 1 GDPR, personal data is all information
        relating to an identified or identifiable natural person. This includes
        information such as your first and last name, your address, your
        telephone number, your e-mail address, but also your IP address.
      </p>
      <p>
        Data which cannot be linked to your person, such as through
        anonymization, is not personal data. Processing (e.g., collection,
        storage, readout, retrieval, use, transmission, deletion or destruction)
        in accordance with Art. 4 No. 2 of the GDPR always requires a legal
        basis or your consent. Processed personal data must be deleted as soon
        as the purpose of the processing has been achieved and there is no
        longer any legally prescribed obligation to retain data.
      </p>
      <p>
        Here you will find information on how we handle your personal data when
        you visit our website. In order to provide the functions and services of
        our website it is necessary for us to collect personal data about you.
      </p>
      <p>
        We also explain the type and scope of the respective data processing,
        the purpose and the corresponding legal basis and the respective storage
        period.
      </p>
      <p>
        This privacy policy applies only to this website. It does not apply to
        other websites to which we merely refer by hyperlink. We cannot assume
        any responsibility for the confidential handling of your personal data
        on these third-party websites, as we have no influence on whether these
        companies comply with the data protection regulations. You can find out
        more about how these companies handle your personal data directly on
        these websites.
      </p>
      <p>Below you will find the contact details of the responsible office.</p>

      <h2>2. Responsible authority</h2>
      <p>
        Responsible for the processing of personal data on this website is (see
        legal notice / imprint):
      </p>
      <p>
        Hanso Pte. Ltd.
        <br />
        30 Petain Road
        <br />
        Singapore 208099
        <br />
        <a href="mailto:info@hanso.group">info@hanso.group</a>
      </p>

      <h2>3. Use of the website / server log files</h2>
      <h3>a) The nature and extent of the data processing</h3>
      <p>
        If you use this website without transmitting data to us in any other way
        (e.g., by registering or using the contact form), we collect technically
        necessary data via server log files, which are automatically transmitted
        to our server, including
        <ul>
          <li>IP address</li>
          <li>Data and time of the request</li>
          <li>Name and URL of the retrieved file</li>
          <li>Website from which the access takes place (Referrer URL)</li>
          <li>Access status/HTTP-status code</li>
          <li>Browser type</li>
          <li>Language and version of the browser software</li>
          <li>Operating system</li>
        </ul>
      </p>

      <h3>
        b) Purpose and legal basis
        <br />
      </h3>
      <p>
        This processing is technically necessary to display our website. We also
        use the data to ensure the security and stability of our website.
      </p>
      <p>
        The legal basis for this processing is Art. 6 (1) (f) GDPR. The
        processing of the aforementioned data is necessary for the provision of
        a website and thus serves to protect a legitimate interest of our
        company.
      </p>

      <h3>c) Storage period</h3>
      <p>
        As soon as the personal data is no longer required to display the
        website, it will be deleted. The collection of the data for the
        provision of the website and the storage of the data in log files is
        mandatory for the operation of the website. There is therefore no
        possibility for the user to object to this aspect. A further storage can
        take place in individual cases, if this is legally required.
      </p>

      <h2>4. Use of Cookies</h2>
      <h3>a) The nature and extent of the data processing</h3>
      <p>
        We use cookies. Cookies are small files that are sent by us to the
        browser of your terminal device when you visit our website and stored
        there.
      </p>
      <p>
        Some functions of our website cannot be offered without the use of
        technically necessary cookies. Other cookies, on the other hand, enable
        us to perform various analyses. For example, some cookies can recognize
        the browser you are using when you visit our website again and transmit
        various information to us. We use cookies to facilitate and improve the
        use of our website. For example, cookies enable us to make our website
        more user-friendly and effective for you, for example by tracking your
        use of our website and determining your preferred settings (e.g.,
        country and language settings). If third parties process information via
        cookies, they collect the information directly via your browser.
        However, cookies do not cause any damage to your end device. They cannot
        execute programs and cannot contain viruses. Various types of cookies
        are used on our website, the type and function of which are explained
        below.
      </p>
      <h4>Temporary cookies/Session cookies</h4>
      <p>
        Our website uses so-called temporary cookies or session cookies, which
        are automatically deleted when you close your browser. Through this type
        of cookies, it is possible to record your session ID. This allows
        various requests from your browser to be assigned to a common session
        and makes it possible to recognize your terminal device during
        subsequent visits to the website.
      </p>
      <h4>Permanent cookies</h4>
      <p>
        So-called permanent cookies are used on our website. Permanent cookies
        are cookies that are stored in your browser over a longer period of time
        and can transmit information. The respective storage period varies
        depending on the cookie. You can delete permanent cookies independently
        via your browser settings.
      </p>
      <h4>Third party cookies</h4>
      <p>
        We use analytical cookies to monitor anonymous user behavior on our
        website.
        <br />
        We also use advertising cookies. These cookies allow us to track user
        behavior for advertising and targeted marketing purposes.
        <br />
        Social media cookies allow us to connect to your social networks and
        share content from our website within your networks.
      </p>
      <h4>Configuration of the browser settings</h4>
      <p>
        Most web browsers are preset to automatically accept cookies. However,
        you can configure your browser so that it only accepts certain cookies
        or none at all. We would like to point out, however, that you may then
        no longer be able to use all the functions of our website.
        <br />
        You can also use your browser settings to delete cookies already stored
        in your browser. Furthermore, it is possible to set your browser to
        notify you before cookies are stored. Since the various browsers may
        differ in their respective functions, we ask you to use the respective
        help menu of your browser for the corresponding configuration options.
        <br />
        Disabling the use of cookies may require a permanent cookie to be stored
        on your computer. If you delete this cookie afterwards, you will have to
        deactivate it again.
      </p>
      <h3>b) Legal basis</h3>
      <p>
        Because of the purposes described, the legal basis for the processing of
        personal data using cookies is Art. 6 (1) (f) GDPR. Only those cookies
        that are technically absolutely necessary to enable you to use the
        website can be based on this.
        <br />
        If you have given us your consent to the use of cookies based on a
        notice ("cookie banner") provided by us on the website, the legal basis
        is additionally Art. 6 (1) (a) GDPR. This is necessary for cookies that
        enable us to analyze your usage behavior and to place appropriate
        advertising.
      </p>
      <h3>c) Storage period</h3>
      <p>
        As soon as the data transmitted to us via cookies is no longer required
        for the purposes described above, this information is deleted. Further
        storage may be carried out in individual cases if this is required by
        law.
      </p>

      <h2>
        5. Data collection for the implementation of pre-contractual measures
        and for the fulfilment of the contract
      </h2>
      <h3>a) The nature and extent of the data processing</h3>
      <p>
        We do not collect any personal data from the users of our website in the
        form of registration or in any other way. However, if a user of the
        website contacts us via the contact options to initiate a contract or
        for other reasons in order to find out more about our services, the data
        specified in the contact will be processed by us.
      </p>
      <h3>b) Purpose and legal basis</h3>
      <p>
        We collect and process this data exclusively for the purpose of
        executing the contract or fulfilling pre-contractual obligations. <br />
        The legal basis for this is Art. 6 (1) (b) GDPR. If you have also given
        your consent, the additional legal basis is Art. 6 (1) (a) GDPR.
      </p>
      <h3>c) Storage period</h3>
      <p>
        The data will be deleted as soon as they are no longer necessary for the
        purpose of their processing.
        <br />
        In addition, there may be statutory retention obligations, for example,
        commercial or tax retention obligations. Insofar as such obligations to
        retain data exist, we will block or delete your data at the end of these
        retention obligations.
      </p>

      <h2>6. Newsletter</h2>
      <h3>a) The nature and extent of the data processing</h3>
      <p>
        When registering for the newsletter, the following data is collected:
        <ul style={{ listStyle: "circle inside none" }}>
          <li>
            the page from which the page was requested (so-called referrer URL)
          </li>
          <li>the date and time of the call</li>
          <li>the description of the type of web browser used</li>
          <li>
            the IP address of the requesting computer, which is shortened in
            such a way that a personal reference can no longer be established
          </li>
          <li>the e-mail-address</li>
          <li>the date and time of registration and confirmation</li>
        </ul>
      </p>
      <h3>b) Purpose and legal basis</h3>
      <p>
        The purpose of the data processing is to send you a newsletter to inform
        you about actions and offers of our company.
        <br />
        The legal basis for this is Art. 6 (1) (a) GDPR. The so-called double
        opt-in procedure is used. This means that after registering, you send a
        separate e-mail to the e-mail address you have provided us with. This
        will request confirmation that you wish to receive the newsletter. In
        this way, your registration can be verified, and any possible misuse of
        your personal data can be clarified. You can revoke your consent to
        receive the newsletter at any time. This can be done either via the link
        provided in all newsletter mails, as an e-mail to info@hanso.group or by
        sending another message to the contact data provided in the imprint.
      </p>
      <h3>c) Storage period</h3>
      <p>
        The data will be deleted as soon as they are no longer required for the
        purpose of processing or you have revoked your consent. In addition,
        there may be legal storage obligations, for example, commercial or tax
        storage obligations. Insofar as such obligations to retain data exist,
        we will block or delete your data at the end of these retention
        obligations.
      </p>

      <h2>7. Data transmission</h2>
      <p>
        We only pass on your personal data to third parties if:
        <ul style={{ listStyle: "lower-latin inside none" }}>
          <li>
            You have given the express consent to do so under Article 6 (1) (a)
            of the GDPR
          </li>
          <li>
            This is permitted by law and, pursuant to Art. 6 (1) (b) GDPR, is
            necessary for the performance of a contractual relationship with you
            or the implementation of pre-contractual measures.
          </li>
          <li>
            According to Art. 6 (1) (c) GDPR, there is a legal obligation for
            the disclosure.
          </li>
          <li>
            We are legally obliged to transfer data to state authorities, e.g.
            tax authorities, social security institutions, health insurance
            companies, supervisory authorities and law enforcement agencies.
          </li>
          <li>
            The transfer of data pursuant to Art. 6 (1) (f) GDPR is necessary to
            safeguard legitimate company interests and to assert, exercise or
            defend legal claims, and there is no reason to assume that you have
            an overriding interest worthy of protection in not disclosing your
            data.
          </li>
          <li>
            In accordance with Art. 28 GDPR, we use external service providers,
            so-called contract processors, who are obliged to handle your data
            with care.
          </li>
        </ul>
        We may use such service providers in the following areas:
        <ul style={{ listStyle: "circle inside none" }}>
          <li>
            IT, logistics, telecommunications, newsletter dispatch, invoicing
          </li>
        </ul>
      </p>
      <p>
        When transferring data to external parties in third countries, i.e.,
        outside the EU/EEA, we ensure that these parties treat your personal
        data with the same care as they would within the EU/EEA. We only
        transfer personal data to third countries where the EU Commission has
        confirmed an adequate level of protection or where we ensure the careful
        handling of personal data through contractual agreements or other
        appropriate guarantees.
        <br />
        We only transfer your data to the USA if the requirements of the
        European Court of Justice ruling from 16.07.2020 - C-311/18 - are
        observed. If you have given us your consent, we will also transfer data
        to companies located in the USA.
      </p>

      <h2>8. Tracking, analysis and other tools</h2>

      <h3>Google Analytics:</h3>
      <p>
        Analysis tool; Google Ireland Limited, Gordon House, Barrow Street,
        Dublin 4, Ireland, Parent company: Google LLC, 1600 Amphitheatre
        Parkway, Mountain View, CA 94043, USA;
        <br />
        With the help of Google Analytics, it is possible for the provider to
        track the behavior of users on the website and, based on this, to revise
        and adapt its own presentation of the offers. This is a legitimate
        interest within the meaning of Art. 6 (1) (f) GDPR.
        <br />
        Website:{" "}
        <a href="https://analytics.google.com/">
          https://analytics.google.com/
        </a>{" "}
        <br />
        Privacy policy:{" "}
        <a href="https://policies.google.com/privacy">
          https://policies.google.com/privacy
        </a>
        <br />
        Privacy Shield:{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active">
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active
        </a>{" "}
        <br />
        Right of Appeal (Opt-Out):{" "}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          https://tools.google.com/dlpage/gaoptout?hl=de
        </a>{" "}
        <br />
        For the setting for advertising, see:{" "}
        <a href="https://adssettings.google.com/authenticated">
          https://adssettings.google.com/authenticated
        </a>
      </p>

      <h3>Twitter:</h3>
      <p>
        Social Network; Twitter Inc., 1355 Market Street, Suite 900, San
        Francisco, CA 94103, USA; <br />
        Website: <a href="https://twitter.com">https://twitter.com</a> <br />
        Privacy policy:{" "}
        <a href="https://twitter.com/de/privacy">
          https://twitter.com/de/privacy
        </a>{" "}
        <br />
        Privacy Shield:{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO">
          https://www.privacyshield.gov/participant?id=a2zt0000000TORzAAO
        </a>
      </p>

      <h3>Facebook:</h3>
      <p>
        Social Network; Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal
        Harbor, Dublin 2, Ireland, Parent company: Facebook, 1 Hacker Way, Menlo
        Park, CA 94025, USA; <br />
        Website: <a href="https://www.facebook.com">https://www.facebook.com</a>
        <br />
        Privacy policy:{" "}
        <a href="https://www.facebook.com/about/privacy">
          https://www.facebook.com/about/privacy
        </a>
        <br />
        Privacy Shield:{" "}
        <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC">
          https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC
        </a>{" "}
        <br />
        Right of Appeal:{" "}
        <a href="https://www.facebook.com/settings?tab=ads">
          https://www.facebook.com/settings?tab=ads
        </a>
      </p>

      <h3>Instagram:</h3>
      <p>
        Social network; Instagram Inc, 1601 Willow Road, Menlo Park, CA, 94025,
        USA;
        <br />
        Website:{" "}
        <a href="https://www.instagram.com">https://www.instagram.com</a>
        <br />
        Privacy policy:{" "}
        <a href="http://instagram.com/about/legal/privacy">
          http://instagram.com/about/legal/privacy
        </a>
      </p>

      <p>
        These listed three (social media) platforms serve the appropriate and
        user-friendly online presentation of the company. This is a legitimate
        interest within the meaning of Art. 6 (1) (f) GDPR.
      </p>

      <h3>WhatsApp:</h3>
      <p>
        Messenger-service; WhatsApp Ireland Limited, 4 Grand Canal Square, Grand
        Canal Harbour, Dublin 2, Irland;
        <br />
        Website: <a href="https://www.whatsapp.com">https://www.whatsapp.com</a>
        <br />
        Privacy policy:{" "}
        <a href="https://www.whatsapp.com/privacy">
          https://www.whatsapp.com/privacy
        </a>
        <br />
        WhatsApp is used by the company to enable a contact option for
        interested parties and to ensure an uncomplicated initiation of a
        contract. On the one hand, this is possible according to Art. 6 (1) (b)
        GDPR. In addition, the uncomplicated accessibility of the company serves
        a legitimate interest according to Art. 6 (1) (f) GDPR.
      </p>

      <h2>9. Rights of data subjects</h2>
      <p>
        Since we collect and process data from you, you have certain rights as a
        data subject of a data processing:
        <ul style={{ listStyle: "circle inside none" }}>
          <li>
            The right to information from us. Should you exercise this right, we
            will inform you which of your personal data we have processed.
          </li>
          <li>Right to correction or deletion</li>
          <li>Right to limit processing</li>
          <li>Right to object to the processing</li>
          <li>Right to data transferability</li>
          <li>Right to withdraw your consent</li>
          <li>Right of appeal to a supervisory authority</li>
          <li>Right to object to the processing</li>
          <li>
            Art. 21 GDPR allows you to object at any time, for reasons arising
            from your particular situation, to the processing of personal data
            concerning you that is carried out on the basis of Art. 6 (1) (e) or
            (f) GDPR.
          </li>
          <li>
            Your right of revocation:
            <br />
            You can revoke your consent to process your personal data at any
            time with effect for the future.
            <br />
            You can declare the revocation by e-mail to info@hanso.group or by
            sending a message to the contact details listed at the beginning of
            this document.
          </li>
        </ul>
      </p>
      <br />
      <strong>22.07.2022</strong>
    </PageContainer>
  </Layout>
)

export default PrivacyPolicy
